import React, { useRef } from 'react';
import AutocompleteField, { type AutocompleteProps } from '../form/fields/autocomplete.field';
import axios, { type CancelTokenSource } from 'axios';
import DonationRepository from '../../api/repository/DonationRepository';

interface Props {
  defaultValue?: string;
  onChange: AutocompleteProps['onChange'];
}

const DonationAutocomplete: React.FC<Props> = ({ defaultValue, onChange }) => {
  let cancelTokenSource = useRef<CancelTokenSource>();

  return (
    <AutocompleteField
      name="autocomplete-donation"
      defaultValue={defaultValue}
      onChange={onChange}
      loadOptions={async (input, callback) => {
        if (cancelTokenSource.current !== undefined) {
          cancelTokenSource.current.cancel();
        }

        cancelTokenSource.current = axios.CancelToken.source();

        return await DonationRepository.getAll({ projectWithName: input, groups: 'short' }, { cancelToken: cancelTokenSource.current.token }).then((response) => {
          callback(
            response.data.results.map((donation) => ({
              value: donation.id,
              label: donation.data.name
            }))
          );
        });
      }}
    />
  );
};

export default DonationAutocomplete;
