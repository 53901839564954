import { type ColumnsType } from '../../../components/table/types/columns.type';
import React from 'react';
import { type DonationDetails } from '../../../api/repository/types/payment.type';
import { Link } from 'react-router-dom';

export const columns: Array<ColumnsType<DonationDetails>> = [
  {
    dataField: 'payment',
    text: 'Payment id',
    formatter: (payment) => {
      if (!payment || !payment.id) {
        return <></>;
      }

      return (
        <>
          <Link to={`/payments/${payment.id}/view`}>{payment.id}</Link>
        </>
      );
    },
    style: { width: 150 }
  },
  {
    dataField: 'payment',
    text: 'Status',
    formatter: (payment) => {
      if (!payment) {
        return <></>;
      }

      return (
        <>
          {payment.status}
          <br />
        </>
      );
    },
    style: { width: 150 }
  },
  {
    dataField: 'payment',
    text: 'Donateur',
    formatter: (payment) => {
      if (!payment || !payment.user|| !payment.user.id) {
        return <></>;
      }
      return <>{payment ? <Link to={`/users/${payment.user.id}/edit/`}>{payment.user.email}</Link> : ''}</>;
    }
  },
  {
    dataField: 'total',
    text: 'Total',
    formatter: (total) => <>{total} €</>
  },
  {
    dataField: 'project',
    text: 'Projet',
    formatter: (project) => {
      return <>{project ? <Link to={`/projects/${project.id}/edit/informations`}>{project.name}</Link> : 'Dons globaux'}</>;
    }
  },
  {
    dataField: 'createdAt',
    text: 'Créé le',
    preRender: {
      type: 'datetime'
    }
  }
];
